import React from 'react';
import RarityHover from './RarityHover';

const Pixi = (props) => {
  return (
    <>
      <>
        <div className="pixi-img-wrapper">
          <img className="head-img" src={props.head} alt="head" />
          <img className="back-img" src={props.back} alt="back" />
          <img className="face-img" src={props.face} alt="face" />
          <img className="ears-img" src={props.ears} alt="ears" />
          <img className="mouth-img" src={props.mouth} alt="mouth" />
          <img className="torso-img" src={props.torso} alt="torso" />
          <img className="eyes-img" src={props.eyes} alt="eyes" />
          <img className="front-img" src={props.front} alt="front" />
          <img
            className="body-img"
            src={'./images/pixi/body_main.png'}
            alt="pixi-body"
            id="pixi-body"
          />{' '}
        </div>{' '}
      </>
      <>
        <div className="pixi-desc-wrapper">
          <h1 className="pixi-title">{props.name}</h1>
          <h3 className="item-lore">"{props.lore}"</h3>
          <div className="rarity-wrapper">
            <h5 className="rarity-text">{props.rarity}</h5>
            <button className="help-btn">
              <img src="./images/help.png" alt="" />
            </button>
            <RarityHover></RarityHover>
          </div>
        </div>
      </>
    </>
  );
};

export default Pixi;
