import React from 'react';

const RarityHover = () => {
  return (
    <div className="rarity-desc-box ">
      <h3>Basic</h3>
      <p>Basic Items can be unlocked by simply leveling up in game</p>
      <hr />
      <h3 className="rare-text">Rare</h3>
      <p>Rare Items appear occasionaly when you level up in game</p>
      <hr />
      <h3>Streamer Drop</h3>
      <p>Streamer Drops can be unlocked by watching partnered streamers</p>
      <hr />
      <h3>Special Event</h3>
      <p>Special Event Items can be unlocked during community and seasonal events</p>
      <hr />
      <h3>Store Item</h3>
      <p>Purchasable from the in-game store</p>
    </div>
  );
};

export default RarityHover;
