import React from 'react';

const Item = (props) => {
  return (
    <div className="item-wrapper" onClick={props.active}>
      <div className="item-image">
        <img src={props.img} alt="" />
      </div>
      <div className="item-name">
        <h3> {props.name}</h3>
      </div>
    </div>
  );
};

export default Item;
