import './App.css';
import Item from './components/Item';
import Pixi from './components/Pixi';
import ItemData from './items.json';
import { useState } from 'react';
import React from 'react';
import MobileWarning from './components/MobileWarning';

function App() {
  //a list of the different item pieces with their initial dfault values set

  const [headPiece, setHeadPiece] = useState(['./images/pixi/head/head_01.png']);
  const [backPiece, setBackPiece] = useState(['./images/pixi/back/back_01.png']);
  const [facePiece, setFacePiece] = useState(['./images/pixi/face/face_01.png']);
  const [earsPiece, setEarsPiece] = useState(['./images/pixi/ears/ears_01.png']);
  const [mouthPiece, setMouthPiece] = useState(['./images/pixi/mouth/mouth_01.png']);
  const [torsoPiece, setTorsoPiece] = useState(['./images/pixi/torso/torso_01.png']);
  const [eyesPiece, setEyesPiece] = useState(['./images/pixi/eyes/eyes_01.png']);
  const [frontPiece, setFrontPiece] = useState(['./images/pixi/front/front_01.png']);
  const [itemName, setItemName] = useState([ItemData.heads[1].name]);
  const [itemRarity, setitemRarity] = useState([ItemData.heads[1].rarity]);
  const [itemLore, setItemLore] = useState('Protects the important parts');

  //Goes through the item arrays and maps them to individual objects ->  passes in that object data to the props of each prop in the Item component → sets the onClick listener to set the prop on the Pixi component to whichever item was clicked

  const listBacks = ItemData.backs.map((back) => (
    // Create an item element and pass in the data from item.json. Move to the next item in the array and repeat for however many elements there are in the array
    <Item
      name={back.name}
      img={back.img}
      lore={back.lore}
      key={back.name}
      rarity={back.rarity}
      active={() => {
        setBackPiece(back.img);
        setItemName(back.name);
        setItemLore(back.lore);
        setitemRarity(back.rarity);
      }}
    />
  ));

  const listHeads = ItemData.heads.map((head) => (
    <Item
      name={head.name}
      img={head.img}
      key={head.name}
      active={() => {
        setHeadPiece(head.img);
        setitemRarity(head.rarity);
        setItemName(head.name);
        setItemLore(head.lore);
      }}
    />
  ));

  const listFaces = ItemData.faces.map((face) => (
    <Item
      name={face.name}
      img={face.img}
      key={face.name}
      active={() => {
        setFacePiece(face.img);
        setitemRarity(face.rarity);
        setItemName(face.name);
        setItemLore(face.lore);
      }}
    />
  ));

  const listEars = ItemData.ears.map((ears) => (
    <Item
      name={ears.name}
      img={ears.img}
      key={ears.name}
      active={() => {
        setEarsPiece(ears.img);
        setitemRarity(ears.rarity);
        setItemName(ears.name);
        setItemLore(ears.lore);
      }}
    />
  ));
  const listMouths = ItemData.mouths.map((mouth) => (
    <Item
      name={mouth.name}
      img={mouth.img}
      key={mouth.name}
      active={() => {
        setMouthPiece(mouth.img);
        setitemRarity(mouth.rarity);
        setItemName(mouth.name);
        setItemLore(mouth.lore);
      }}
    />
  ));
  const listTorsos = ItemData.torsos.map((torso) => (
    <Item
      name={torso.name}
      img={torso.img}
      key={torso.name}
      active={() => {
        setTorsoPiece(torso.img);
        setitemRarity(torso.rarity);
        setItemName(torso.name);
        setItemLore(torso.lore);
      }}
    />
  ));
  const listEyes = ItemData.eyes.map((eyes) => (
    <Item
      name={eyes.name}
      img={eyes.img}
      key={eyes.name}
      active={() => {
        setEyesPiece(eyes.img);
        setitemRarity(eyes.rarity);
        setItemName(eyes.name);
        setItemLore(eyes.lore);
      }}
    />
  ));
  const listFront = ItemData.front.map((front) => (
    <Item
      name={front.name}
      img={front.img}
      key={front.name}
      active={() => {
        setFrontPiece(front.img);
        setitemRarity(front.rarity);
        setItemName(front.name);
        setItemLore(front.lore);
      }}
    />
  ));

  //Set the default active category to 'backs'. This will display all the back items when the page first loads. This is down here because it first has to be set using the listBacks function above
  const [activeCategory, setActiveCategory] = useState([listBacks]);
  return (
    <div className="App">
      <MobileWarning className="mobile-warning" />
      <div className="main-wrapper">
        <div className="main-title">
          <h1>Pixi Dressing Room</h1>
          <p>
            Pixi Dressing Room is a free companion site for{' '}
            <span className="tnb-link">
              {' '}
              <a href="https://store.steampowered.com/app/2025660/Trust_No_Bunny/">
                {' '}
                Trust No Bunny's
              </a>
            </span>{' '}
            customizable avatars. Here you can see all the different costume parts you can collect
            as well as how you can unlock them!
          </p>
        </div>

        <div className="pixi-wrapper">
          {/* Sets all the Pixi component image elements by passing in the default images that were set at the top of the app  */}
          <Pixi
            back={backPiece}
            head={headPiece}
            face={facePiece}
            ears={earsPiece}
            mouth={mouthPiece}
            torso={torsoPiece}
            eyes={eyesPiece}
            front={frontPiece}
            rarity={itemRarity}
            name={itemName}
            lore={itemLore}
          />
        </div>

        {/* This is the category picker. Each button,when clicked, will set its values as the active category */}
        <div className="category-wrapper">
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listBacks);
            }}
          >
            Back
          </button>
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listFaces);
            }}
          >
            Face
          </button>
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listEars);
            }}
          >
            Ears
          </button>
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listHeads);
            }}
          >
            Head
          </button>
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listMouths);
            }}
          >
            Mouth
          </button>
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listTorsos);
            }}
          >
            Torso
          </button>
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listEyes);
            }}
          >
            Eyes
          </button>
          <button
            className="category-button"
            type="button"
            onClick={() => {
              setActiveCategory(listFront);
            }}
          >
            Front
          </button>
        </div>
        {/* Displays all the items in the current activeCategory */}
        <div className="items-container">{activeCategory}</div>
      </div>
    </div>
  );
}

export default App;
